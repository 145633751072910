<template>
  <v-container fluid>
    <div class="d-flex justify-end ">
      <v-chip class="ma-2" color="azul_med" label dark>
        <v-icon start>mdi-chevron-right-circle-outline</v-icon>
        <v-list-item-subtitle>Activas: {{ this.activas }}</v-list-item-subtitle>
      </v-chip>
      <v-chip class="ma-2" color="rojoalert" label dark>
        <v-icon start>mdi-alert-circle-outline</v-icon>
        <v-list-item-subtitle>Impago: {{ this.suspendidas }}</v-list-item-subtitle>
      </v-chip>
      <v-chip class="ma-2" color="grey" label dark>
        <v-icon start>mdi-alert-circle-outline</v-icon>
        <v-list-item-subtitle>Bajas: {{ this.totalbajas }}</v-list-item-subtitle>
      </v-chip>
    </div>
    <p class="font-weight-regular">
      Listado de <strong>membresías</strong>, puedes agrupar por colectividad.
    </p>
    <v-row no-gutters class="bordered-row">
      <!-- <v-col class="mr-1">
        <list-agentes @agent-change="changeAgent"></list-agentes>
      </v-col> -->
      <v-col class="mr-1">
        <list-colectivos :propAdmin="false" @colectivo-change="changeColectividad"></list-colectivos>
      </v-col>
      <v-col class="mr-1">
        <v-btn color="azul_med" @click.native="buscarColectivo" dark>Buscar</v-btn>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col sm="12" md="4" lg="4" xl="4">
        <v-text-field class="col" v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details
          outlined dense @change="comp_change()"></v-text-field>
      </v-col>
    </v-row>
    <!--group-by="AgeNom" show-select -1       fixed-header
      height="400px"-->
    <v-data-table :items-per-page="5" calculate-widths :headers="headers" :items="contratos" item-key="GUID"
      :search="search" :group-by="['Agente']" :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-text': 'Filas por página'
        }" no-data-text="No hay datos disponibles">
      <template v-slot:[`item.Plan`]="{ item }">
        <v-card color="azul_med" dark class="mini-card">
          <v-card-title style="font-size: 12px;margin: 6px;padding: 0%;display: match;">{{ item.Plan }}</v-card-title>
          <v-card-text style="font-size: 10px;margin: 6px; margin-top: 0%;">
            <p style="margin: 0%;padding: 0%;">Citas restantes: <strong>{{ item.NumCitas }}</strong><br />
              Urgencias: <strong>{{ $formatCurrency(item.MontoUrgencias) }}</strong><br />
              Coberturas: <strong>{{ item.Coberturas }}</strong>
            </p>
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:[`item.ContNumTel`]="{ item }">
        <td><small><a v-if="item.ContNumTel" @click="$copyText(item.ContNumTel)">{{ item.ContNumTel }} </a><br />
            <a @click="$copyText(item.ContEmail)" v-if="item.ContEmail">{{ item.ContEmail
              }}</a></small></td>
      </template>
      <template v-slot:[`item.GUID`]="{ item }">
        <td style="color: #6598C1;">{{ item.GUID }}</td>
      </template>
      <!-- @click:row="handleRowClick" -->
      <template v-slot:[`item.Estatus`]="{ item }">
        <td><span v-if="item.Estatus === 1" class="estatus-activo">Activo</span>
          <span v-if="item.Estatus === 2" class="estatus-impago">Impago</span>
          <span v-if="item.Estatus === 0" class="estatus-expirado">Baja</span>
        </td>
      </template>
      <template v-slot:[`item.MontoUrgencias`]="{ item }">
        <td>
          <span v-if="item.MontoUrgencias === 0" class="urgencias0">
            {{ $formatCurrency(item.MontoUrgencias) }}
          </span>
          <span v-else>
            {{ $formatCurrency(item.MontoUrgencias) }}
          </span>
        </td>
      </template>
      <template v-slot:[`item.FecIniVig`]="{ item }">
        <td>{{ $formatDate(item.FecIniVig) }}</td>
      </template>
      <template v-slot:[`item.DatosActualizadosPortal`]="{ item }">
        <td> <span v-if="item.DatosActualizadosPortal"> {{ $formatDate(item.DatosActualizadosPortal) }}</span>
          <span v-else><v-icon dense color="red darken-2">
              mdi-close
            </v-icon></span>
        </td>
      </template>
      <template v-slot:[`item.Titular`]="{ item }">
        <td><small> {{ item.Titular }}</small></td>
      </template>
      <template v-slot:[`item.Contratante`]="{ item }">
        <td>
          <v-chip v-if="item.Colectividad" style="max-width: 160px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" class="ma-2"
            color="green" label text-color="white" x-small>
            <v-icon left>
              mdi-label
            </v-icon>
            {{ item.Colectividad }}
          </v-chip><br />
          <small
            v-if="item.Contratante.replace(/\s+/g, '').toUpperCase() != item.Titular.replace(/\s+/g, '').toUpperCase()">
            {{ item.Contratante }}
          </small></td>
      </template>
      <template v-slot:[`item.Colectividad`]="{ item }">
        <td>
          <small>{{ item.Colectividad }}</small>
        </td>
      </template>
      <template v-slot:[`item.Membresia`]="{ item }">
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn red icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item :disabled="!(item.Estatus === 1)" @click="editItem(item)">
              <v-list-item-title>
                <ButtonMembresia :propObjTarjet="item"></ButtonMembresia>
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="verPagos(item.GUID, item.TotRecibos)">
              <v-list-item-title> Ver recibos</v-list-item-title>
            </v-list-item>
            <v-list-item @click="verBeneficiarios(item.GUID)">
              <v-list-item-title> Ver Beneficiarios</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <list-pagos :isOpen.sync="isModLisPagos" :Membresia="this.MembresiaSelec"
      :NumRecibos="this.NumRecibosSelec"></list-pagos>
    <list-beneficiarios :isOpen.sync="isModLisBen" :Membresia="this.MembresiaSelec"></list-beneficiarios>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import ApiBase from '@/functions/axios/axiosNetBase'


export default {
  name: 'Membresias',
  components: {
    ButtonMembresia: () => import('@/components/ButtonMembresia.vue'),
    ListColectivos: () => import('@/components/Forms/ColectivosListComponent.vue'),
    ListPagos: () => import('@/components/ListPagoComponent.vue'),
    // ListAgentes: () => import('@/components/Forms/AgentesListComponent.vue'),
    ListBeneficiarios: () => import('@/components/ListBeneficiariosComponent'),
  },
  computed: {
    ...mapGetters(['getCODAGE'])
  },
  mounted() {
    this.getMembresiasAgente()
  },
  created() {
    // this.getMembresiasAgente()
  },
  data() {
    return {
      grouped: {},
      totalbajas: 0,
      MembresiaSelec: null,
      NumRecibosSelec: 0,
      isModLisBen: false,
      isModLisPagos: false,
      idcolectividad: null,
      idagente: null,
      activas: 0,
      suspendidas: 0,
      search: '',
      contratos: [],
      headers: [
        { text: 'Agente', value: 'Agente', sortable: false },
        { text: 'Membresia', value: 'GUID', sortable: false },
        //{ text: 'Citas', value: 'NumCitas', sortable: false },
        //{ text: 'Urgencias', value: 'MontoUrgencias', sortable: false },
        { text: 'Plan', value: 'Plan', sortable: false },
        //{ text: 'Coberturas', value: 'Coberturas', sortable: false },
        { text: 'Vigencia Inicio', value: 'FecIniVig', sortable: false },
        { text: 'Contratante', value: 'Contratante', sortable: false },
        { text: 'Titular', value: 'Titular', sortable: false },
        //{ text: 'Colectividad', value: 'Colectividad', sortable: false },
        { text: 'Contacto', value: 'ContNumTel', sortable: false },
        //{ text: 'Recibos', value: 'TotRecibos', sortable: false },

        { text: 'Estatus', value: 'Estatus', sortable: false },

        { text: 'Opciones', value: 'Membresia', sortable: false },
      ]
    }
  },
  methods: {
    verPagos(mem, numrecibos) {
      this.MembresiaSelec = mem
      this.NumRecibosSelec = numrecibos
      this.isModLisPagos = true
    },
    verBeneficiarios(mem) {
      this.MembresiaSelec = mem
      this.isModLisBen = true
    },
    buscarColectivo() {
      this.getMembresiasAgente()
    },
    changeColectividad(value) {
      this.idcolectividad = value
    },
    changeAgent(value) {
      this.idagente = value
    },
    getMembresiasAgente() {
      console.log(this.getCODAGE)

      if(this.getCODAGE && this.getCODAGE !== 'null'){
        this.$loading(true)
      //var url = this.idcolectividad !== null ? `getMembresias?CodCole=${this.idcolectividad}` : `getMembresias`
      var url = `getMembresiasAgente?Agente=${this.getCODAGE}&Colectivo=${this.idcolectividad}`
      ApiBase(
        url,
        'GET'
      ).then((response) => {
        if (response.status === 200) {
          if (response.data) {
            this.contratos = response.data
            this.activas = this.contratos.filter(item => item.Estatus === 1).length;
            this.suspendidas = this.contratos.filter(item => item.Estatus === 2).length;
            this.totalbajas = this.contratos.filter(item => item.Estatus === 0).length;
            this.$loading(false)
          }
        }
      })
        .catch((error) => {
          this.$loading(false)
          this.$swal({
            position: '',
            html: `Ha ocurrido un error inesperado membresías agente, <b>intenta mas tarde</b> . ${error}`,
            icon: 'error',
            confirmButtonText: 'Cerrar',
            showCloseButton: true,
            showConfirmButton: true,
          })
        });
      }

    }
  }
}

</script>
<style lang="scss">
.v-data-table {
  overflow-x: auto;
  /* Hace que la tabla sea desplazable horizontalmente si es necesario */
}

.mini-card {
  width: 150px;
  /* Ajusta el ancho */
  height: 100px;
  /* Ajusta la altura */
  font-size: 0.875rem;
  /* Tamaño de texto más pequeño */
}
</style>
